interface NewBookingInterface {
  bookOnAccount: boolean;
  byTheHour: boolean | null;
  hoursDuration: number | null;
  pickup: string;
  pickupLat: number | null;
  pickupLng: number | null;
  destination: string;
  destinationLat: number | null;
  destinationLng: number | null;
  jobStart: string | null;
  passengers: number | null;
  flight: string | null;
  pickupInstructions?: string;
  poNumber: string | null;
  nameOnSign: string | null;
  name: string;
  email: string;
  mobile: string;
  vehicleCats: number[];
}

export class NewBooking implements NewBookingInterface {
  bookOnAccount = false;
  byTheHour: boolean | null = null;
  hoursDuration: number | null = null;
  pickup = "";
  pickupLat: number | null = null;
  pickupLng: number | null = null;
  destination = "";
  destinationLat: number | null = null;
  destinationLng: number | null = null;
  jobStart: string | null = "";
  jobDate = "";
  jobTime = "";
  passengers: number | null = null;
  flight = null;
  pickupInstructions?: string;
  poNumber: string | null = null;
  nameOnSign: string | null = null;
  clientRef1: string | null = null;
  _name = "";
  email = "";
  mobile = "";
  vehicleCats: number[] = [];

  constructor(data?: NewBookingInterface) {
    if (data) {
      Object.assign(this, data);
    }
  }

  set name(name: string) {
    this._name = name;
    this.clientRef1 = name;
  }

  get name() {
    return this._name;
  }

  toJSON() {
    const data = {
      byTheHour: this.byTheHour,
      hoursDuration: this.hoursDuration,
      pickup: this.pickup,
      pickupLat: this.pickupLat,
      pickupLng: this.pickupLng,
      destination: this.destination,
      destinationLat: this.destinationLat,
      destinationLng: this.destinationLng,
      jobStart: `${this.jobDate} ${this.jobTime}`.trim(),
      passengers: this.passengers,
      flight: this.flight,
      pickupInstructions: this.pickupInstructions,
      poNumber: this.poNumber,
      nameOnSign: this.nameOnSign,
      clientRef1: this.clientRef1,
      name: this.name,
      email: this.email,
      mobile: this.mobile,
      clientContact: this.mobile,
      vehicleCats: this.vehicleCats,
    };

    return data;
  }
}

export interface VehicleCatPrice {
  booking: {
    price: number | null;
    priceFormatted?: number;
  };
  vehicleCat: {
    id: number;
    name: string;
    bags?: number;
    capacity?: number;
    image?: string;
  };
}

export interface Booking {
  id: number;
  hash: string;
  price: number;
  priceFormatted: number;
  jobStart: string;
  jobStartFormatted: string;
}

export interface PaymentIntent {
  clientSecret: string;
}
