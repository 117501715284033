import client, { responseBody } from "./client";
import { NewBooking, Booking, PaymentIntent } from "@/models/book-now";
import { LoginResponse } from "@/models/login";

const baseUrl = "/v2/book-now";

export default {
  getVehiclePrices: (data: NewBooking) =>
    client.post(`${baseUrl}/vehicle-cats`, data).then(responseBody),
  saveBooking: (data: NewBooking) =>
    client.post(`${baseUrl}/book`, data).then<Booking>(responseBody),
  getBooking: (
    id: number | string,
    hash: string,
    params?: Record<string, unknown>
  ) =>
    client
      .get(`${baseUrl}/booking/${id}/${hash}`, { params })
      .then<Booking>(responseBody),
  createPaymentIntent: (id: number | string, hash: string) =>
    client
      .post(`${baseUrl}/payment-intent/${id}/${hash}`)
      .then<PaymentIntent>(responseBody),
  getViewBookingLoginToken: (
    id: number | string,
    hash: string,
    intentId?: string
  ) =>
    client
      .get(`${baseUrl}/view-booking-login-token/${id}/${hash}`, {
        params: { intentId },
      })
      .then<LoginResponse | Record<string, never>>(responseBody),
};
