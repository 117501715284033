import { defineStore, storeToRefs } from 'pinia'
import API from '@/api/book-now'
import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import { NewBooking } from '@/models/book-now'
import type { VehicleCatPrice } from '@/models/book-now'
import { useUserStore } from '@/store/user'

type StoreType = {
    form: NewBooking;
    vehicleCats: VehicleCatPrice[];
    selectedVehicleCat: VehicleCatPrice | null;
}

export const useBookNowStore = defineStore('bookNow', {
    state: (): StoreType => ({
        form: new NewBooking(),
        vehicleCats: [],
        selectedVehicleCat: null,
    }),
    actions: {
        async getVehiclePrices() {
            this.vehicleCats = await API.getVehiclePrices(this.form)
        },
        saveBooking() {
            return API.saveBooking(this.form)
        },
        selectVehicleCat(cat: VehicleCatPrice | null) {
            this.form.vehicleCats = cat ? [cat.vehicleCat.id] : []
            this.selectedVehicleCat = cat
        },
        resetForm() {
            this.$reset()
        },
        isVehicleCatSelected(id?: number) {
            if (id) {
                return this.form.vehicleCats.includes(id)
            }

            return this.form.vehicleCats.length > 0
        },
        getBooking(id: number, hash: string, opts?: { payByCard: number }) {
            return API.getBooking(id, hash, opts);
        },
        createPaymentIntent(id: number, hash: string) {
            return API.createPaymentIntent(id, hash);
        },
        async loginUserByPaymentIntent(id: number, hash: string, paymentIntentId: string) {
            const { token } = await API.getViewBookingLoginToken(
                id,
                hash,
                paymentIntentId,
            )

            const userStore = useUserStore()
            userStore.setAuthToken(token)
        },
    },
})


export function useBookNow() {
    const store = useBookNowStore()
    const { error, errors } = useApiErrors('/vehicle-cats')
    const { isPending } = useApiRequests('/vehicle-cats')

    const { form, vehicleCats } = storeToRefs(store)


    return {
        form,
        vehicleCats,
        error,
        errors,
        isPending,
        getVehiclePrices: store.getVehiclePrices,
        selectVehicleCat: store.selectVehicleCat,
        isVehicleCatSelected: store.isVehicleCatSelected,
        resetForm: store.resetForm,
    }
}


export function useBookNowSummary() {
    const store = useBookNowStore()
    const { error, errors } = useApiErrors('/book')
    const { isPending } = useApiRequests('/book')

    const { form, selectedVehicleCat } = storeToRefs(store)


    return {
        form,
        selectedVehicleCat,
        error,
        errors,
        isPending,
        saveBooking: store.saveBooking,
    }
}

export function useBookNowPayByCard(id: number, hash: string) {
    const store = useBookNowStore()

    const uri = `/payment-intent/${id}/${hash}`
    const { error } = useApiErrors(uri)
    const { isPending } = useApiRequests(uri)

    return {
        error,
        isPending,
        getBooking: () => store.getBooking(id, hash, { payByCard: 1 }),
        createPaymentIntent: () => store.createPaymentIntent(id, hash),
        loginUserByPaymentIntent: (intent: string) => store.loginUserByPaymentIntent(id, hash, intent),
    }
}